import { Nav } from './nav';

export const NAVLINKS = [
    {
      href: "#about",
      title: "About"
    }, {
      href: "#services",
      title: "Services"
    }, {
      href: "#gallery",
      title: "Gallery"
    }, {
      href: "#contact",
      title: "Contact"
    }
];
