import { Header } from './header';

export const HEADERIMAGES = [
    {
      src: "../assets/ArchShingles.jpg",
      alt: "Arch Shingles"
    },
    {
      src: "../assets/AsphaltShingles4.jpg",
      alt: "Asphalt Shingles"
    },
    {
      src: "../assets/CedarShakes1.jpg",
      alt: "Cedar Shakes"
    },
    {
      src: "../assets/HomePage.jpg",
      alt: "Metal"
    }
];
